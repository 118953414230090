import { Controller } from "stimulus";

export default class extends Controller {
  connect () {
  }

  toggle () {
    const toggleClasses = JSON.parse(this.element.dataset.toggleClasses)

    toggleClasses.forEach(toggleClass => {
      this.element.classList.toggle(toggleClass)
    })
  }
}