import { Controller } from "stimulus"

export default class extends Controller {
    static values = {
        timeout: { type: Number, default: 5000 },
    }

    connect() {
        setTimeout(() => {
            this.element.remove()
        }, this.timeoutValue)
    }
}