<template>
  <form
      v-bind:accept-charset="$props.acceptCharset"
      v-bind:action="$props.action"
      v-bind:data-values="$props.dataValues"
      v-bind:method="$props.method"
      v-bind:enctype="$props.enctype"
      v-on:submit="handleSubmit"
      v-on:ajax:beforeSend="ajaxBeforeSend"
      ref="form"
  >
    <slot></slot>
  </form>
</template>

<script>
export default {

  props: {
    "acceptCharset": {
      type: String,
      required: true,
      default: 'UTF-8'
    },
    "action": {
      type: String,
      required: true,
    },
    "enctype": {
      type: String,
    },
    "method": {
      type: String,
      required: true,
      default: "post"
    },
    "autoSubmit": {
      //allowed values: 'onChange'
      type: String,
      default: ''
    }
  },
  data: function() {
    return { timer: null }
  },
  computed: {
    values: function(){
      return this.$store.getters.getValue(
        this.$store.getters.getMeta('modelName')
      )
    },
  },
  methods:{
     "debounce": function(timeout = 500){
       clearTimeout(this.timer);
       console.log("debounce return function")

       // GOTCHA: DO NOT USE form.submit() because it does not play well with HOTWIRE
       //  * instead, use form.requestSubmit() that acts as if a submit button were clicked
       //    and therefor is compatible with HOTWIRE
       this.timer = setTimeout(() => {  this.$refs.form.requestSubmit(); }, timeout);
     },
    "handleSubmit": function(event) {
      if (this.$store.getters.getMeta('disableValidation')) {
        return // early exit (will continue with the submit)
      }

      this.$store.commit('setTouched', {
            value: true,
            name: `${this.$store.getters.getMeta('modelName')}._submit`
          }
      )

      if (!this.$store.getters.getError(`${this.$store.getters.getMeta('modelName')}._is_valid`)) {
        // invalid data => du not submit
        event.preventDefault();
      }
    },

    "ajaxBeforeSend": function(event) {
      if (!this.$store.getters.getError(`${this.$store.getters.getMeta('modelName')}._is_valid`)) {
        // ignore RailsUjs: make that when using rails ujs with remote:true (aka local:false) we do not send the form
        // if the data is valid note: there is no test for this specific case !!
        event.preventDefault();
      }
    }
  },
  watch: {
    values: function(value, _oldValue) {
      console.log("watch values")
      if (this.$props.autoSubmit === 'onChange') {
        console.log("watch values ==> onChange")
        this.debounce(750)
      }
    }
  }

}

</script>
