import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "headers", "footers"]

  connect () {
    this.align()
  }

  align() {
    if (this.hasHeadersTarget && this.hasFootersTarget) {
      const headers = this.headersTarget.querySelectorAll('th')
      const footers = this.footersTarget.querySelectorAll('td')

      for (let i = 0; i < headers.length; i++) {
        const header = headers[i];
        const footer = Array.from(footers).find(footer => footer.getAttribute('headers') === header.id);

        if (footer) {
          footer.style.width = header.offsetWidth + 'px';
          footer.style.height = header.offsetHeight + 'px';
        }
      }
    }
  }
}