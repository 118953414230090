import { Controller } from "stimulus"

export default class extends Controller {

  static values = {
    url: String
  }

  filter (event) {
    document.getElementById('cash_position').src = this.urlValue;
  }
}