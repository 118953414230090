import { Controller } from "stimulus"
import { FormStore } from  'k-form-js'
import Form from "../forms/components/form.vue"
import CopyFromTicket from '../forms/components/copy_from_ticket.vue'
import turbo_frame_plugin from "../forms/plugins/turbo-frame"
import computed_values_plugin from "../forms/plugins/computed_values"
import update_shipment_ticket_attributes_plugin from "../forms/plugins/update_shipment_ticket_attributes"

// OPTIONAL: import a custom vuex plugin that allows adding additional behaviour
// import plugin from 'path/to/plugin'

// OPTIONAL: use custom components
// import CustomComponent from 'path/to/plugin'

export default class extends Controller {

  static values = {
    initialTouch: Boolean, // if true then all inputs are considered as touched (by default the value is false)
    disableValidation: Boolean, // if true then user inputs will not be revalidated on the fly
    hideValidationErrors: Boolean // if true then the validatin messages will not be displayed
  }

  getHttpMethod(element) {
    let methodInput = element.querySelector("input[name='_method']");
    if (methodInput) {
      return methodInput.value
    } else {
      return null
    }

  }
  connect() {
    let { controller, values: serializedValues, validationUrl, ...others} = this.element.dataset
    let values = JSON.parse(serializedValues || {});
    
    let authenticityToken = this.element.querySelector("input[name='authenticity_token']")?.value
    let httpMethod = this.getHttpMethod(this.element) || 'POST'
    let globalAuthenticityToken = document.querySelectorAll('meta[name=csrf-token]')[0].content

    const app = new FormStore(Object.assign( {
      authenticityToken: authenticityToken,
      additionalComponents: {
        'k-copy-from-ticket': CopyFromTicket,
        'k-form': Form, // override Form in order to support automatic submit
       // 'k-shipment-period': Date,
        // 'k-quantity-measure-unit': QuantityMeasureUnit,
       // 'k-fixed-price-futures-date': FixedPriceFuturesDate,
      }, // optional
      plugins:[
          turbo_frame_plugin,
          computed_values_plugin,
          update_shipment_ticket_attributes_plugin
      ],  // optional
      element: this.element.firstElementChild,
      globalAuthenticityToken: globalAuthenticityToken,
      httpMethod: httpMethod,
      validationUrl: validationUrl,
      values: values,
      initialTouch: this.initialTouchValue,
      disableValidation: this.disableValidationValue,
      hideValidationErrors: this.hideValidationErrorsValue,
    }, others)).app
  }
}
