import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ["password"];

    connect() {

    }

    reveal(_event) {
        this.passwordTarget.type = "text";
    }
    reset(_event) {
        this.passwordTarget.type = "password";
    }
}