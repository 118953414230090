const plugin = (store) => {
    store.subscribeAction({
        before: (action, state) => {
            // called before every action.
            // The action comes in the format of `{ type, payload }`.
        },
        after: (action, state) => {
            // called before every action.
            // The action comes in the format of `{ type, payload }`.

            if (action.type !== 'dataReceivedFromServer' ) {
                return;
            }

            let computedValuesNames =  [
                "sale[total_quantity]",
                "sale[remaining_quantity]",
                "sale[total_nb_of_lots]",
                "purchase[total_quantity]",
                "purchase[remaining_quantity]",
                "purchase[total_nb_of_lots]",
                "purchase[final_price]",
                "shipment[transit_time]",
                "shipment[total_cost]",
                "shipment[sale_attributes][total_quantity]",
                "shipment[sale_attributes][remaining_quantity]",
                "shipment[sale_attributes][product_id]",
                "shipment[purchase_attributes][total_quantity]",
                "shipment[purchase_attributes][remaining_quantity]",
                "shipment[purchase_attributes][final_price]",
                "shipment[purchase_attributes][product_id]",
            ]

            computedValuesNames.forEach(function(name) {
                let currentValue = store.getters.getValue(name)
                let suggestedValue = store.getters.getSuggestedValues(name)

                if (name.includes('final_price')) {
                    let fixedPriceName = name.replace('final_price', 'fixed_price')
                    let fixedPriceValue = store.getters.getValue(fixedPriceName)
                    console.log("fixedPriceName", fixedPriceName)
                    console.log("suggestedValue", suggestedValue)
                    console.log("fixedPriceValue", fixedPriceValue)
                    if (fixedPriceValue === true) {
                        return
                    }
                }

                if (suggestedValue !== currentValue) {
                    store.commit('setValue', {
                            value: suggestedValue,
                            name: name
                        }
                    )
                }
            })
        },
    });


    // called when the store is initialized
    store.subscribe((mutation, state) => {
        // called after every mutation.
        // The mutation comes in the format of `{ type, payload }`.
    });
};

export default plugin;
