import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ["subheaderBlock", "paddedBox"];

    connect() {
    }

    paddedBoxTargetConnected() {
        this.adaptPadTop()
    }

    adaptPadTop() {
        let newPadTop = 44 + this.subheaderBlockTarget.offsetHeight + "px";
        this.paddedBoxTarget.style.paddingTop = newPadTop;
    }
}