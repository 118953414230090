const plugin = (store) => {
    store.subscribeAction({
        before: (action, state) => {
            // called before every action.
            // The action comes in the format of `{ type, payload }`.
            if (action.type !== 'dataReceivedFromServer' ) {
                return;
            }

            if (action.payload?.shipment?.sale_attributes) {
                // if the shipment[sale_attributes][id] is present this means the shipment is using an existing sale
                // => keep a copy of the attributres in shipment[sale_attributes_2] (this is used by the copy_from_ticket component)
                // same for shipment[purchase_attributes]
                let currentSaleId = store.getters.getValue('shipment[sale_attributes_2][id]') // currently in the store
                let newSaleId = action.payload.shipment.sale_attributes_2.id // received from the server
                if (currentSaleId !== newSaleId) {
                    store.commit('setValue', {
                            value: action.payload.shipment.sale_attributes_2,
                            name: 'shipment[sale_attributes_2]'
                        }
                    )

                    // // the user switched from an existing sale to a new shipment
                    // // => get a new set of sale attributes from the server
                    // if (currentSaleId && !newSaleId) {
                    //     store.commit('setValue', {
                    //             value: action.payload.shipment.sale_attributes,
                    //             name: 'shipment[sale_attributes]'
                    //         }
                    //     )
                    // }

                }




            }

            if (action.payload?.shipment?.purchase_attributes) {
                let currentPurchaseId = store.getters.getValue('shipment[purchase_attributes_2][id]') // currently in the store
                let newPurchaseId = action.payload.shipment.purchase_attributes_2.id // received from the server
                if (currentPurchaseId !== newPurchaseId) {
                    store.commit('setValue', {
                            value: action.payload.shipment.purchase_attributes_2,
                            name: 'shipment[purchase_attributes_2]'
                        }
                    )
                    // // the user switched from an existing sale to a new shipment
                    // // => get a new set of purchase attributes from the server
                    // if (currentPurchaseId && !newPurchaseId) {
                    //     store.commit('setValue', {
                    //             value: action.payload.shipment.purchase_attributes,
                    //             name: 'shipment[purchase_attributes]'
                    //         }
                    //     )
                    // }
                }
            }

           // let currentValue = store.getters.getValue(name)
           // let suggestedValue = store.getters.getSuggestedValues(name)
           // if (suggestedValue !== currentValue) {
           //     store.commit('setValue', {
           //             value: suggestedValue,
            //            name: name
           //         }
           //     )
           // }



        },
        after: (action, state) => {
            // called before every action.
            // The action comes in the format of `{ type, payload }`.


        },
    });


    // called when the store is initialized
    store.subscribe((mutation, state) => {
        // called after every mutation.
        // The mutation comes in the format of `{ type, payload }`.
    });
};

export default plugin;
