// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import { Application } from '@hotwired/stimulus'
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "../pagy.js.erb"

import * as Routes from '../routes.js.erb';
window.Routes = Routes;

Rails.start()
ActiveStorage.start()

import "controllers"
import Autosave from 'stimulus-rails-autosave'
import '../stylesheets/application'
require.context('../stylesheets/botyglot/default-theme/20-static/', true)
require.context('../static/', true)
import '../botyglot/_bootstrap_custom'
import '../botyglot'
//toto


const application = Application.start()
application.register('autosave', Autosave)